<template>
	<v-container fluid fill-height>
		<div class="bg"></div>
		<div class="bg bg2"></div>
		<div class="bg bg3"></div>
		<v-row class="justify-center align-center" style="height: 90vh">
			<v-card class="mx-auto pa-7 sizing" style="opacity: 90%" dark>
				<center>
					<h1>404</h1>
					<h2>
						The page that you are looking for is lost in the void
					</h2>
					<br />
					<v-btn :to="buttonLink" light>
						<v-icon class="mr-2">mdi-home</v-icon>
						<h3>Go home</h3>
					</v-btn>
				</center>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
export default {
	computed: {
		buttonLink() {
			if (this.$store.state.PreviousRoute) {
				switch (this.$store.state.PreviousRoute.headerFooter) {
					case "Shoot":
						return "/shoot";
					case "Club":
						return "/club";
					default:
						return "/";
				}
			}
			return "/";
		},
	},
};
</script>

<style scoped>
h1 {
	font-size: 76pt;
}

.bg {
	animation: slide 3s ease-in-out infinite alternate;
	background-image: linear-gradient(-60deg, #f05a28 50%, #681500 50%);
	bottom: 0;
	left: -50%;
	opacity: 0.5;
	position: fixed;
	right: -50%;
	top: 0;
	/* z-index: -1; */
}

.bg2 {
	animation-direction: alternate-reverse;
	animation-duration: 4s;
}

.bg3 {
	animation-duration: 5s;
}

.content {
	background-color: rgba(255, 255, 255, 0.8);
	border-radius: 0.25em;
	box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.25);
	box-sizing: border-box;
	left: 50%;
	padding: 10vmin;
	position: fixed;
	text-align: center;
	top: 50%;
	transform: translate(-50%, -50%);
}

h1 {
	font-family: monospace;
}

@keyframes slide {
	0% {
		transform: translateX(-25%);
	}
	100% {
		transform: translateX(25%);
	}
}
</style>

<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

@media #{map-get($display-breakpoints, 'md-and-down')}

	.sizing
		max-width: 300px

@media #{map-get($display-breakpoints, 'lg-and-up')}

	.sizing
		max-width: 500px
</style>
